@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.Header {
  width: 100%;
  height: 70px;
  padding: 14px 16px 14px 32px;
  display: flex;
  justify-content: space-between;

  @include onMobile {
    height: 112px;
    padding: 14px 16px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    background: $red;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .logo {
    height: 100%;

    @include onMobile {
      display: none;
    }
  }

  .searchBarContainer {
    display: none;

    @include onMobile {
      display: block;
    }
  }

  .userBlock {
    display: flex;
    align-items: center;
    column-gap: 16px;

    @include onMobile {
      margin-left: auto;
    }

    .username {
      font-weight: 500;
      font-size: 20px;
      line-height: 89%;
      color: $text;

      @include onMobile {
        display: none;
      }
    }

    .userMenuContainer {
      position: relative;
      z-index: 3;

      .userMenu {
        width: 296px;
        margin: 0;
        padding: 32px;
        display: none;
        flex-direction: column;
        row-gap: 16px;
        position: absolute;
        top: 58px;
        right: 2px;
        border-radius: 16px;
        list-style: none;
        background-color: #ffffff;

        &.isVisible {
          display: flex;
        }

        @include onMobile {
          padding: 16px;

          .profileButton {
            display: none;
          }
        }

        li {
          button {
            height: 48px;
            padding: 0;
            display: flex;
            flex: 1 1 100%;
            column-gap: 16px;
            justify-content: space-between;
            align-items: center;
            font-size: 24px;
            line-height: 100%;
            border: none;
            background-color: transparent;
            color: $text;
          }
        }
      }
    }

    .userIcon,
    .userPhoto {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;

      @include onMobile {
        height: 46px;
        width: 46px;
        background: #ffffff;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  .backdrop {
    display: none;

    @include onMobile {
      display: block;
      position: fixed;

      &.isVisible {
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 2;
      }
    }
  }
}