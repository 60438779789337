@import '../../assets/styles/variables';

.MediaContainer {
  flex: 1;

  &.notSafari {
    .player {
      aspect-ratio: 909 / 529;
    }
  }

  .player {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    // overflow: hidden;
    background-color: $primary;
    aspect-ratio: 909 / 575;
    z-index: 2;
    border-radius: 16px;

    &.screenRecording {
      &::after {
        content: 'Screen recording in progress';
        position: absolute;
        z-index: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: var(--bottom);
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 27.5px;
        font-weight: bold;
        font-size: 36px;
        color: white;
        background-color: $primary;
        border-radius: 16px;
      }
    }

    &.audio {
      background-image: none;
    }

    .saveSectionSuccess,
    .confirmSectionRemoval {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      .backdrop {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 16px;
        background-color: rgba(0, 0, 0, 0.3);
      }

      .popup {
        position: absolute;
        top: calc(50% - 42px);
        left: calc(50% - 208px);
        width: 417px;
        height: 84px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        background-color: white;
        border-radius: 10px;

        .closeIcon {
          position: absolute;
          top: 10px;
          left: 10px;
          font-size: 0;
          width: 25px;
          height: 25px;
          background-image: url(../../assets/images/closeIcon.png);
          background-size: contain;
          background-repeat: no-repeat;
          cursor: pointer;
        }
      }
    }

    .saveSectionSuccess {
      .popup {
        flex-direction: column;
        height: auto;
        padding-top: 25px;
        padding-bottom: 25px;
        span {
          display: block;
          max-width: 300px;
        }
      }
    }

    .timerOverlay {
      position: absolute;
      z-index: 9999;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      background-color: rgba(0, 0, 0, 0.3);

      .timer {
        display: flex;
        justify-content: center;
        align-items: center;
        // min-width: 25%;
        width: 183px;
        // aspect-ratio: 1;
        font-size: 100px;
        color: white;
        font-weight: bold;
        border: 15px solid white;
        border-radius: 50%;
      }
    }

    video {
      position: relative;
      width: 100%;
      height: calc(100% - 45px);
      margin-bottom: 8px;
      object-fit: cover;
      border-radius: 22px;

      &.absolute {
        position: absolute;
        visibility: hidden;
      }
    }

    .audioRecorder {
      width: 100%;
      height: calc(100% - 45px);
      background-image: url(../../assets/images/audio-background.png);
      background-size: cover;
      background-position: center;
      // aspect-ratio: 909 / 529;
    }

    .recordingTime {
      position: absolute;
      z-index: 1;
      bottom: 19px;
      right: 60px;
      color: white;
      font-weight: bold;
    }

    .recordIcon {
      position: absolute;
      z-index: 1;
      top: 40px;
      right: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 53px;
      height: 25px;
      padding-right: 2px;
      padding-left: 2px;
      color: white;
      text-align: right;
      font-weight: bold;
      border: 1px solid white;

      .red {
        width: 15px;
        height: 15px;
        background-color: $red;
        border-radius: 50%;
        animation: blink-animation 1s steps(2, start) infinite;
      }
    }
  }
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
