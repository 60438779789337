@import '../../assets/styles/variables';

.dialog {
  max-width: 860px;
  max-height: 1000px;
}

.FolderUsersModal {
  padding: 48px;
  position: relative;
  border-radius: 15px;

  .closeIcon {
    position: absolute;
    top: 36px;
    right: 36px;
    cursor: pointer;
  }

  .content {
    h2 {
      margin-bottom: 40px;
      font-size: 36px;
    }

    .usersContainer {
      h3 {
        margin-bottom: 20px;
        color: rgba(0, 0, 0, 0.5);
        font-size: 22px;
      }

      .usersList {
        height: 274px;
        margin: 0 0 20px 0;
        padding: 0 20px 0 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
        overflow-y: auto;
        list-style: none;

        .userItem {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .userInfoContainer {
            display: flex;
            align-items: center;

            img {
              height: 64px;
              width: 64px;
              margin-right: 20px;
              border-radius: 50%;
              object-fit: cover;
            }

            .userInfo {
              display: flex;
              flex-direction: column;

              .userName,
              .userTitle {
                font-size: 18px;
                font-weight: bold;
              }

              .userEmail {
                color: $text;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}
