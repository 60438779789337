@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.CreateMeetingFooter {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 238px;
  padding-left: 104px;
  padding-right: 104px;
  background-color: #f9f9f9;

  @include onMobile {
    display: none;
  }

  .header {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 28px;
    background-color: $primary;

    h1 {
      position: absolute;
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 100%;
      color: #ffffff;
      text-transform: uppercase;
    }
  }

  .footerContainer {
    flex: 1;
    max-width: 1172px;

    .sectionsListContainer {
      position: relative;
      height: 100%;

      .switch {
        position: absolute;
        top: 91px;
        left: 0;
        z-index: 1;
        cursor: pointer;

        &.switchRight {
          left: unset;
          right: -16px;
        }
      }

      .sectionsList {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        padding-top: 48px;
        list-style: none;
        overflow: hidden;

        .inner {
          position: absolute;
          height: 140px;
          display: flex;
          align-items: center;
          gap: 40px;
          padding-left: 30px;
          transition: left 0.2s;

          li {
            padding: 0;
            margin: 0;

            &.section {
              position: relative;
              width: 232px;
              min-width: 232px;
              height: 140px;
              cursor: pointer;

              &.active {
                &::after {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  display: block;
                  width: 232px;
                  height: 140px;
                  border: 5px solid $red;
                  border-radius: 16px;
                  pointer-events: none;
                }
              }

              .username {
                position: absolute;
                z-index: 1;
                color: white;
                background-color: $primary;
                padding-left: 15px;
                padding-right: 15px;
                border-radius: 15px;
                white-space: nowrap;

                &.defaultIcon {
                  filter: invert(100%);
                }
              }

              .sectionContentContainer {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $primary;
                border-radius: 16px;
              }

              .title {
                position: absolute;
                left: calc(50% - 68px);
                bottom: -32px;
                display: flex;
                align-items: center;
                width: 136px;
                height: 24px;
                padding-left: 18px;
                padding-right: 18px;
                background-color: $primary;
                border-radius: 16px;

                h1 {
                  width: 100%;
                  margin: 0;
                  color: $text;
                  font-size: 16px;
                  text-align: center;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                input {
                  width: 100%;
                  color: #ffffff;
                  text-align: center;
                  background-color: transparent;
                  border: none;
                  line-height: 150%;

                  &:focus {
                    outline: none;
                  }
                }
              }

              .audioBackgroundContainer {
                width: 100%;
                height: 100%;
                background-color: $primary;
                border-radius: 16px;

                span {
                  width: 100%;
                  line-height: 140px;
                  display: block;
                  text-align: center;
                  font-weight: bold;
                  color: #ffffff;
                }
              }

              &.contributed {
                .usericon {
                  position: absolute;
                  bottom: -10px;
                  right: -10px;
                  width: 60px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 60px;
                  background-color: white;
                  border-radius: 50%;
                  filter: invert(100%);
                  z-index: 2;

                  &.withAvatar {
                    filter: none;
                  }

                  img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    object-fit: cover;
                  }
                }
              }

              .noteSectionContent {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                background-color: #efede9;
                border-radius: 16px;

                img {
                  width: 100px;
                  height: auto;
                }
              }

              .questionSectionContent,
              .assignedSectionContent {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 56px;
                font-weight: bold;
                height: 100%;
                width: 100%;
                border-radius: 16px;
                background-color: #efefef;
              }

              .assignedSectionContent {
                span {
                  position: absolute;
                  font-size: 16px;
                  font-weight: bold;
                }
              }

              .processingSectionContent {
                height: 100%;
                width: 100%;
                padding: 0 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $primary;
                border-radius: 16px;

                .progressBarOuter {
                  position: relative;
                  width: 100%;
                  height: 4px;
                  border-radius: 16px;
                  overflow: hidden;
                  background: white;

                  .progressBarInner {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    background-color: $red;
                  }
                }
              }

              &.placeholder {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 16px;
                background-color: #EFEFEF;

                span {
                  font-weight: bold;
                  position: absolute;
                  white-space: nowrap;
                }
              }

              .deleteIcon {
                position: absolute;
                z-index: 1;
                top: -16px;
                right: -16px;
                font-size: 0;

                svg {
                  path {
                    transform: translate(12px, 10px);
                  }
                }
              }

              .saveIcon {
                position: absolute;
                z-index: 1;
                top: -15px;
                right: 24px;
              }

              video,
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 16px;
              }
            }

            .sectionMenuContainer {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 140px;
              min-width: 140px;
              height: 140px;
            }
          }
        }
      }
    }
  }
}
