@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.LibraryPage {
  min-height: 100vh;

  img {
    vertical-align: unset;
  }

  .container {
    padding: 16px 72px;

    @include onMobile {
      padding: 16px 32px;
    }

    .libraryHeader {
      height: 62px;
      margin-bottom: 32px;
      display: flex;
      position: relative;
      line-break: anywhere;

      @include onMobile {
        height: unset;
        margin-bottom: 16px;
      }

      h2 {
        margin-bottom: 0;

        @include onMobile {
          display: none;
        }
      }

      .buttonsContainer {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 20px;

        @include onMobile {
          display: none;
        }
      }
    }

    .subfolders {
      margin-bottom: 80px;
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;

      @include onMobile {
        margin-bottom: 0;
        flex-direction: column;
        gap: 0;
      }

      .subfolderContainer {
        display: flex;
        flex-basis: calc(33% - 12px);
        align-items: center;
      }
    }

    .tableContainer {
      display: flex;
      justify-content: center;

      .table {
        width: 100%;
        table-layout: fixed;

        tr {
          position: relative;
        }

        thead tr,
        tbody tr:not(:last-of-type) {
          &::after {
            content: '';
            height: 1px;
            display: block;
            position: absolute;
            left: 8px;
            right: 8px;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.05);

            @include onMobile {
              display: none;
            }
          }
        }

        thead {
          height: 50px;

          @include onMobile {
            display: none;
          }

          tr {
            th {
              padding: 0 32px 12px;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 112%;
              color: $text;
            }
          }
        }

        tbody {
          tr {
            td {
              height: 120px;
              padding: 8px 32px 8px;
              font-family: 'Inter';
              font-size: 20px;
              line-height: 125%;
              color: $text;

              @include onMobile {
                height: unset;
              }

              button {
                padding: 0;
                background-color: transparent;
                border: none;

                &:disabled {
                  cursor: not-allowed;
                  opacity: 0.5;
                }
              }

              &.capitalize {
                text-transform: capitalize;
              }

              &.title {
                padding-left: 0;
                margin-left: 32px;
                display: flex;
                align-items: center;

                @include onMobile {
                  min-height: 70px;
                  padding-right: 0;
                  margin-left: 0;
                  justify-content: space-between;
                  font-size: 18px;
                  font-weight: 600;
                }

                .innerContainer {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  img {
                    margin-right: 16px;
                  }

                  .publishedDate {
                    display: none;

                    @include onMobile {
                      display: block;
                      font-size: 14px;
                      font-weight: normal;
                    }
                  }
                }

                img {
                  width: 48px;
                  height: 48px;

                  @include onMobile {
                    width: 36px;
                    height: 36px;
                  }
                }

                .playButton {
                  display: none;

                  @include onMobile {
                    display: flex;
                    align-items: center;
                  }
                }
              }

              &.owner {
                @include onMobile {
                  display: none;
                }
              }

              &.publishedDate {
                @include onMobile {
                  display: none;
                }
              }

              &.buttons {
                padding-right: 20px;
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                gap: 24px;

                @include onMobile {
                  display: none;
                }

                .duration {
                  text-align: center;
                  min-width: 80px;
                }
              }
            }

            &:hover::before {
              content: '';
              height: 100px;
              width: 100%;
              display: block;
              position: absolute;
              top: 10px;
              left: 0;
              background-color: #f9f9f9;
              z-index: -1;
              border-radius: 40px;

              @include onMobile {
                display: none;
              }
            }
          }
        }
      }
    }

    .empty {
      margin: 200px 0 0;
      text-align: center;
      font-weight: 400;
      font-size: 42px;
      line-height: 114%;
      color: $text;
    }

    .paginationContainer {
      display: flex;
      justify-content: flex-end;

      .buttonsList {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
      }

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;

        &.disabled {
          opacity: 0.4;

          a {
            cursor: not-allowed;
          }
        }

        &.active {
          a {
            width: 24px;
            text-align: center;
            font-family: 'Inter';
            line-height: 125%;
            border-radius: 4px;
            background-color: $pink;
            color: #ffffff;
          }
        }

        a {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 125%;
          text-decoration: none;
          color: $text;
        }
      }
    }
  }
}