@import '../../assets/styles/variables';

.ViewerReportPage {
  min-height: 100vh;
  background-color: #f9f9f9;

  .container {
    padding: 20px 40px;

    header {
      display: flex;
      justify-content: space-between;

      h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 133%;
        color: $text;
      }
  
      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 133%;
        color: $text;
      }
    }

    .reportInfo {
      display: flex;
      justify-content: space-between;

      .meetingTitle {
        margin-bottom: 28px;
      }
  
      p {
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 24px;
        line-height: 150%;
        color: $text;
      }
  
      .completionRate {
        margin-bottom: 0;
      }

      .buttonContainer {
        display: flex;
        align-items: flex-end;
      }
    }
  }

  table {
    width: 100%;

    thead {
      height: 50px;

      tr {
        background: $pink;

        th {
          padding: 16px 32px;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 112%;
          color: #ffffff;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 24px 32px;
          font-family: 'Inter';
          font-size: 20px;
          font-weight: 500;
          line-height: 125%;
          color: $text;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);;
        }
      }
    }
  }
}
