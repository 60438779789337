@import '../../../assets/styles/variables';

.SignIn {
  h1 {
    margin: 64px 0 0;
    align-self: flex-start;
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 108%;
    color: $text;
  }

  h2 {
    margin-bottom: 72px;
    align-self: flex-start;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    font-size: 24px;
    line-height: 190%;
    color: $text;
  }

  form {
    width: 100%;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    row-gap: 56px;

    .row {
      margin-bottom: 32px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .forgotPassword {
        margin: -21px 0 0;
        align-self: flex-start;
        line-height: 112%;
        color: $text;

        a {
          color: $pink;
          text-decoration: none;
        }
      }

      button {
        width: 220px;
        height: 74px;
        font-weight: 500;
        font-size: 24px;
        line-height: 100%;
      }
    }
  }

  .close {
    align-self: flex-end;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    text-decoration-line: underline;
    color: $pink;
    cursor: pointer;
  }
}
