@import '../../assets/styles/variables';

.Switch {
  position: relative;
  display: inline-block;
  width: 51px;
  height: 31px;

  & input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $pink;
    }

    &:checked + .slider:before {
      transform: translateX(20px);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    background: rgba(120, 120, 128, 0.32);
    transition: background-color 0.2s;

    &::before {
      content: '';
      width: 27px;
      height: 27px;
      position: absolute;
      right: calc(50% - 27px / 2 + 10px);
      top: calc(50% - 27px / 2);
      border-radius: 50%;
      background-color: #ffffff;
      border: 0.5px solid rgba(0, 0, 0, 0.04);
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15),
        0px 3px 1px rgba(0, 0, 0, 0.06);
      transition: transform 0.2s;
    }
  }
}
