@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.EditableTitle {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  .tooltip {
    position: relative;
    display: inline-block;
    padding-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    white-space: nowrap;

    @include onMobile {
      font-size: 18px;
      font-weight: 600;
      max-width: 70vw;
    }

    & + .tooltiptext {
      visibility: hidden;
      width: max-content;
      top: 100%;
      background-color: white;
      border: 1px solid $gray;
      color: $text;
      text-align: center;
      padding: 2px 5px;
      border-radius: 3px;
      font-size: 0.75rem;
  
      position: absolute;
      z-index: 1;

      &::after {
        content: " ";
        position: absolute;
        bottom: 100%;
        left: 30%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent $gray;
      }
    }

    &:hover + .tooltiptext {
      visibility: visible;
    }
  }

  .editButton {
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    right: 10px;
    top: -6px;
    transform: translateX(100%);
    background-image: (url('../../../assets/images/my-meetings/edit.svg'));
    background-repeat: no-repeat;
    background-size: contain;
    background-color: unset;
    border: none;
  }

  input {
    width: 100%;
    margin-bottom: -2px;
    padding: 0;
    outline: none;
    border: none;
    border-bottom: 2px dashed $primary;
    background-color: transparent;
  }
}
