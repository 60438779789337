@import '../../../../assets/styles/variables';

.Progress {
  width: 200px;
  height: 2px;
  background: #e9e9e9;

  .bar {
    height: 100%;
    background: $red;
  }

  .step {
    text-transform: capitalize;
    font-size: 12px;
  }
}